import { GridColDef, GridRowModesModel } from "@mui/x-data-grid";
import { DataGridRowsBlockComponent } from "../../ui/blocks/data-grid.component"
import { Button, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { TagManagerDeleteAllTagsService, TagManagerDeleteTagGroupService, TagManagerDeleteTagService, TagManagerGetService, TagManagerPCTUpdateService } from "./tags.services";
import { NavLink, useNavigate } from "react-router-dom";
import { filterMuiColumns } from "../../../helpers/arrayworks.helper";
import { AlertContext } from "../../../providers/alert.provider";
import { ButtonsTableMiniActions } from "../../ui/buttons";
import { UserToolsTagManagerCreateComponent } from ".";
import { IResponse } from "../../../interfaces/response.interface";
import { ModalContext } from "../../../providers/modal.provider";
import { ModalContentGeneric } from "../../ui/blocks";
import IComponentState, { ComponentStateDto, ComponentStateLoadingDto, ComponentStateReadyDto } from "../../../interfaces/component-state.interface";
import ButtonBack from "../../ui/buttons/button-back";
import TagDocsList from "./tags-list-docs";
import UIBlockTabsComponent from "../../ui/blocks/tabs";
import BlockLoaderTransparency from "../../ui/loader";
import DeleteIcon from '@mui/icons-material/Delete';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OutputIcon from '@mui/icons-material/Output';

const TagsList: React.FC<{lockGroup: (id: string) => void, id: string, loadTagList?: (func?: () => any) => void }> = ({ id, loadTagList, lockGroup }) => {
    const [ state, setState ] = useState<IComponentState>(ComponentStateDto);
    const [ tagDocs, setTagDocs ] = useState<any[]>([]);
    const [ tagGroup, setTagGroup ] = useState<any>({});
    const [ rowModesModel ] = useState<GridRowModesModel>({})
    const [ title, setTitle ] = useState<string>('');
    const [ titleToggle, setTitleToggle ] = useState<boolean>(false);
    const [ search, setSearch ] = useState<string>('');
    const [ engageSearch, setEngageSearch ] = useState<boolean>(false);
    // const [ toggleRowActions, setToggleRowActions ] = useState<boolean>(false);
    const { setMsg, setType, setAutoClose } = useContext<any>(AlertContext);
    const { setModalActive, setModalBody, setModalTitle } = useContext<any>(ModalContext);

    const nav = useNavigate();

    const editPctEvent = async (e: any) => {
        TagManagerPCTUpdateService(e).then((r: IResponse<any>) => {
            if(r.success) {
                setMsg('PCT value updated successfully.');
                setType(true);
            } else {
                setType(false);
                setMsg(r.error?.match('truncated')? 'PCT value is formatted incorrectly.': r.error);
            }
            setAutoClose(3000);
            fetchTags(search);
        }).catch((e) => {
            setType(false);
            setMsg(e.message || 'An error occurred while updating the PCT value.');
            fetchTags(search);
        });
        return e;
    };

    const fetchTags = (search?: string) => {
        TagManagerGetService(id, search? {search} : undefined).then((r: { success: boolean, error?: string, data: any }) => {
            if(r.success) {
                const list = r.data?.tag_list?.rows || [];
                let tg = r.data;
                if(!tg?.description) {
                    setMsg('Tag group not found.');
                    setType(false);
                    setState(ComponentStateReadyDto);
                    return;
                }
                delete tg.tag_list?.rows;
                if(tg?.docs?.rows) {
                    setTagDocs(tg.docs.rows);
                    delete tg.docs;
                }
                setTagGroup(tg);
                setTitle(tg?.description);
                setState({...ComponentStateReadyDto, data: list});
            } else {
                setState(ComponentStateReadyDto);
            }
        });
    }

    useEffect(() => {
        if(!state.ready) {
            if(!state.loading) {
                setState(ComponentStateLoadingDto);
                fetchTags(search);
                setEngageSearch(true);
            }
        }
    }, [ ]);

    useEffect(() => {
        if (engageSearch) {
            const second = setTimeout(() => {
                setState(ComponentStateLoadingDto);
                fetchTags(search);
            }, 1000);
            return () => {
                clearTimeout(second);
            };
        }
    }, [search]);
    
    const backButton = (title?: string, size: 'sm' | 'md' | 'lg' = 'sm', className: string = 'no-appearance') => (
        <button className={`${className} uppercase txt-corp`} onClick={() => {
            if(loadTagList)
                loadTagList(() => nav('/tag-manager'));
            else
                nav('/tag-manager')
        }} style={{fontSize: !size || size === 'sm'? '1em' : size === 'md'? '1.5em' : '2em' }}><ButtonBack text={ title } /></button>
    )

    const isDisabled: boolean = tagGroup?.verified_at || tagGroup?.submitted_at || tagGroup?.denied_at? true : false;

    const isLockable: boolean = tagDocs.length > 0;

    const tagsColumns: GridColDef[] = [
        { field: 'created_at', headerName: 'Date', minWidth: 200, },
        { field: 'pct', headerName: 'PCT', flex: 1, minWidth: 200, editable: !isDisabled, },
        { field: 'replacement_id', headerName: 'Replacement Id', minWidth: 200 },
        // { field: 'in_use', headerName: 'Used', renderCell: (e: any) => e.row.in_use? <SwapHorizIcon color="warning" /> : e.row.herd_archived? <OutputIcon color="success" /> : null },
        { field: 'gid', headerName: 'Assigned', headerAlign: 'center', cellClassName: 'd-flex justify-content-center align-items-center', minWidth: 60, renderCell: (e: any) => (e.value || (e.row.in_use === 1)? (
            <Tooltip title={e.row.in_use? "Pending" : null}>
                <NavLink to={ e.row.in_use? '#' : `/edit/animal-list/${e.value}` }>
                    <CheckCircleOutlineIcon color={e.row.in_use? "warning" : e.row.herd_archived? "error" : "success" } />
                </NavLink>
            </Tooltip> ) : '') },
        { field: 'herd_archived', headerName: 'Disposed', renderCell: (e: any) => e.row.herd_archived && e.row.in_use? <OutputIcon color="error" /> : e.row.herd_archived && <OutputIcon color="warning" />},
        //{ field: 'non_pct', headerName: 'Secondary', minWidth: 150, flex: 1, editable: !isDisabled, },
        { field: 'actions', headerName: '', cellClassName: 'justify-content-end', renderCell: (e: any) => !e.row.verified_at && 
        <ButtonsTableMiniActions
            onDeleteEvent={ () => {
                TagManagerDeleteTagService({id: e.row.ID, action: 'tag', tid: tagGroup.ID}).then(r => {
                    setType(r.success? true : false);
                    setMsg(r.success? 'Tag deleted successfully.' : r.error || 'An error occurred while deleting the tag.');
                    setState(ComponentStateLoadingDto);
                    fetchTags(search);
                    if(loadTagList)
                        loadTagList();
                });
        }}
            disableDelete={ isDisabled }
        >
        </ButtonsTableMiniActions> },
    ];

    return (
        state.ready && tagGroup?.ID? 
        <>
        <Typography variant="h6">
            { !titleToggle && <>Viewing: <span onClick={() => setTitleToggle(true) }>{ title }</span></> }
            { titleToggle && <TextField fullWidth variant="outlined" autoFocus={true} onBlur={ () => {
                TagManagerPCTUpdateService({ id, description: title, action: 'update_title' }).then(() => {
                    setTitleToggle(false);
                    if(loadTagList)
                        loadTagList();
                });
            } } value={ title } onChange={(e) => setTitle(e.target.value)} /> }
        </Typography>

        <Typography variant="body2" className="mb-1 md-grey">Created At: { tagGroup?.created_at }</Typography>
        <div className="mb-4 col-count-1 gapped">
            <div>
                <div className={`badge bkg-${tagGroup?.verified_at? 'green' : 'red'}`}>{ tagGroup?.verified_at? '' : 'UN' }VERIFIED</div>
            </div>
            <div className="d-flex justify-content-start">
                <div className="col-count-2 auto-width gapped col-c1-md">
                    <div>
                        <Button
                            variant="outlined"
                            className="ml-2b py-2 px-4 rounded-lg full-width-md"
                            size="small"
                            disabled={ isDisabled || !isLockable }
                            onClick={ () => !isDisabled && isLockable? lockGroup(tagGroup.ID) : null }
                        >
                            { tagGroup?.verified_at || isDisabled? <><LockIcon />&nbsp;Group Is Locked</> : <><LockOpenIcon />&nbsp;Lock Group { !isLockable && '(Disabled)' }</> }
                        </Button>
                    </div>
                    <div>
                        { !tagGroup?.verified_at && !tagGroup?.submitted_at && (
                        <Button
                            variant="outlined"
                            className="ml-2b py-2 px-4 rounded-lg full-width-md"
                            size="small"
                            onClick={ () => {
                                setModalTitle('Delete Tag Group?');
                                setModalBody( 
                                    <ModalContentGeneric
                                        title="Delete Tag Group?"
                                        description="Are you sure you want to delete this tag group?"
                                        onSubmitEvent={ async (toType, toMsg) => {
                                            try {
                                                const r = await TagManagerDeleteTagGroupService(tagGroup.ID, 'tag_group');
                                                if(r.success) {
                                                    if(loadTagList)
                                                        loadTagList(() => nav('/tag-manager'));
                                                    setModalActive(false);
                                                    toMsg('Tag group deleted successfully.');
                                                    toType(true);
                                                } else {
                                                    toType(false);
                                                    toMsg(r.error || 'An error occurred while deleting the tag group.');
                                                }
                                            } catch(e: any) {
                                                toType(false);
                                                toMsg(e.message || 'An error occurred while deleting the tag group.');
                                            }
                                        } }
                                        onCancelEvent={ () => setModalActive(false) }
                                        submitText="Delete"
                                    />);
                                setModalActive(true);
                            }}><DeleteIcon />&nbsp;Delete Tag Group
                        </Button>
                        ) }
                    </div>
                </div>
            </div>
        </div>
        <div className="mb-3">
            { backButton() }
        </div>
    
        <BlockLoaderTransparency loading={ state.loading }>
            { state.ready && 
            <UIBlockTabsComponent
                variant="contained"
                tabs={[
                    { 
                        label: 'Tags',
                        shortcode: 'tags',
                        content: (<>
                            <div>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={ search || '' }
                                    placeholder="Search"
                                    onChange={ (e) => setSearch(e.target.value) }
                                    className="mb-4"
                                />
                            </div>
                            <DataGridRowsBlockComponent
                                processRowUpdate={ editPctEvent }
                                // onRowsModelChange={ (e: string[]) => setToggleRowActions(count(e) > 0) }
                                rowModesModel={ rowModesModel }
                                columns={ filterMuiColumns(tagsColumns, state?.data || [], ['actions', 'verified_at', 'non_pct']) }
                                rows={ state?.data || [] }
                                checkbox={ !isDisabled }
                                onRowsDeleteEvent={ async (rows: any) => {
                                    if(isDisabled) {
                                        setType(false);
                                        setMsg('You can not delete when your tag group when in this status.');
                                        return;
                                    }
                                    const ids = rows.map((r: any) => r.toString());
                                    if(ids.length === 0) {
                                        setType(false);
                                        setMsg('No tags selected.');
                                        return;
                                    }
                                    await TagManagerDeleteAllTagsService({ ids, action: 'all_tags' });
                                    fetchTags();
                                    if(loadTagList)
                                        loadTagList();
                                }}
                                label={ (
                                    <div className="align-middle py-4">
                                        <Typography variant="body1">Choose the <span className="badge bkg-corp-grad">ADD</span> tab to add tags into this group.</Typography>
                                    </div>
                                ) }
                            >
                            </DataGridRowsBlockComponent>
                        </>)
                    },
                    {
                        label: 'Documents',
                        shortcode: 'docs',
                        content: (
                            <TagDocsList
                                tagGroup={ tagGroup }
                                docs={ tagDocs }
                                disabled={ isDisabled }
                                className="mb-3"
                                reloadList={ fetchTags }
                            /> )
                    },
                    {
                        label: 'Add',
                        shortcode: 'add',
                        content: ( isDisabled? '' :
                            <UserToolsTagManagerCreateComponent
                                hideTitle
                                title={ tagGroup?.description }
                                curr={ tagGroup?.ID }
                                tid={ tagGroup?.ID }
                                action={ 'upload_tags'}
                                csvEnabled={ true }
                                onFailedEvent={ (r: IResponse<any>) => {
                                    setType(false);
                                    setMsg(r.error);
                                } }
                                onSuccessfulEvent={ () => {
                                    if(loadTagList)
                                        loadTagList();
                                    fetchTags();
                                }}
                                hideUpload
                            /> ),
                        ignore: tagGroup?.verified_at
                    }
                ]} defaultTab="tags"
            /> }
        </BlockLoaderTransparency>
        </> : 
    state.ready && 
    <>
        <div className="mb-3">
            { backButton() }
        </div>
        <div className="align-middle">
            <Typography variant="h3"><b>ERROR: 404</b></Typography>
            <Typography variant="h6"><LabelOffIcon style={{transform: 'rotate(45deg)'}} />&nbsp;Tag Group Not Found</Typography>
            { backButton('OKAY', 'sm', 'border outlined rounded-xlg bkg-transparent py-3 px-4') }
        </div>
    </>
    )
}

export default TagsList;